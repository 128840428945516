* {
  list-style-type: none;
  box-sizing: border-box;
  padding: 0;
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}