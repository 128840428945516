// .card-container {
//     display: flex;
//     justify-content: space-between;

//     @media #{$tablet} {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-evenly;
//     }

//     @media #{$laptop} {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-evenly;
//     }



//     .card-item {
//         height: 500px;
//         border-radius: 6px;
//         border: 1px solid #e3e3e3;
//         margin: 20px;

//         &:nth-child(2) {
//             img {
//                 object-fit: cover;
//             }
//         }

//         &:nth-child(3) {
//             img {
//                 object-fit: cover;
//             }
//         }


//         @media #{$mobile} {
//             max-width: 100%;
//         }

//         .media {


//             img {
//                 width: 100%;
//                 max-width: 250px;
//                 height: 250px;
//                 //object-fit: cover;
//             }


//         }

//         .card_text {
//             display: flex;
//             flex-direction: column;
//             align-items: center;

//             h3 {
//                 font-size: 22px;
//                 font-weight: 400;
//                 max-width: 140px;
//                 margin: 25px 0;
//                 text-align: center;
//                 height: 60px;
//             }

//             p {
//                 font-size: 18px;
//                 font-weight: bold;
//                 color: #666;
//             }

//             .btn {
//                 border-radius: 4px;
//                 font-size: 16px;
//                 border: 2px solid $softGreen;
//                 background-color: $softGreen;
//                 color: #fff;
//                 padding: 10px 25px;
//                 transition: 0.2s;
//             }

//             .btn:hover {
//                 background-color: rgb(0, 78, 8);
//                 border: 2px solid rgb(0, 78, 8);
//             }
//         }
//     }
// }

.card_desk {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media #{$mobile} {
        justify-content: space-evenly;
    }

    @media #{$tablet} {
        justify-content: space-evenly;
    }


    .card_item {
        max-width: 250px;
        margin-top: 20px;
        height: 500px;
        width: 100%;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        text-align: center;


        img {
            width: 100%;
            max-width: 250px;
        }

        .card_description {
            height: 180px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

        }

        .card_text_top {
            font-size: 18px;
            font-weight: bold;
            margin: 10px auto 10px auto;
            max-width: 80%;
        }

        .card_text_bottom {
            font-size: 14px;
            max-width: 80%;
            margin: 10px auto 10px auto;
            color: rgb(61, 61, 61);
            line-height: 150%;
        }

        .card_price {
            font-weight: bold;
            font-size: 16px;
            max-width: 80%;
            margin: 10px auto 10px auto;
            color: $softGreen;
        }

        .card_button {
            padding: 13px 25px;
            width: 100%;
            background-color: $softGreen;
            border-radius: 5px;
            color: white;
            font-size: 16px;
            margin: 10px auto 40px auto;
            cursor: pointer;

            &:hover {
                background-color: rgb(0, 78, 8);
            }
        }
    }
}