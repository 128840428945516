table {
    margin: 25px auto;
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
    // border-spacing: 2px;

    tr {
        background: #F4F5FB;
        height: 51px;

        td {
            padding: 12px;
            border: 5px solid white;
            color: black;
            font-size: 14px;
        }


        td:nth-child(1) {
            padding-left: 25px;
        }

        td:nth-child(2) {
            width: 32%;
            text-align: center;
        }
    }

    thead {
        tr {
            font-weight: bold;
            font-size: 18px;
            text-transform: uppercase;
            background: $softGreen;

            td {
                padding-left: 15px;
                border: 0;
                color: #F4F5FB;
            }
        }

    }

}