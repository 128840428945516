    .head {
        max-width: 50%;
        text-align: center;
        margin: 0 auto;

        @media #{$mobile} {
            max-width: 100%;
        }

        h2 {
            font-size: 36px;
            margin-top: 10px;
        }

        p {
            font-size: 18px;
            line-height: 30px;
        }

        hr {
            margin-top: 30px;
            border: none;
            border-top: 2px solid #000;
            max-width: 45px;
            margin-bottom: 45px;
        }
    }

    .promo-block {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;

        @media #{$mobile} {
            flex-direction: column;
        }

        // @media #{$tablet} {
        //     flex-direction: column;
        // }

        .promo-item {
            // display: flex;
            max-width: 35%;
            margin-bottom: 20px;

            @media #{$laptop} {
                max-width: 45%;
            }

            @media #{$mobile} {
                flex-direction: column;
                max-width: 100%;
            }
        }

        .promo-media {
            display: flex;
            justify-content: center;

            img {
                max-width: 80%;


                @media #{$laptop} {
                    max-width: 80%;
                }
            }

        }


        .block_item {
            flex-direction: column;
            max-width: 100%;
            text-align: center;

            //border: 2px solid red;
            // @media #{$tablet} {
            //     max-width: 40%;
            // }

            @media #{$mobile} {
                max-width: 400px;
                margin-bottom: 20px;
                margin-top: 20px;
                margin: 20px auto;
            }

            h3 {
                a {
                    font-size: 24px;
                    font-weight: 600;
                    color: $softGreen;

                    // &:hover {
                    //     color: $softGreen;
                    // }
                }

                min-height: 80px;
                margin-top: 15px;

            }

            p {
                font-size: 18px;
                line-height: 30px;
                padding-bottom: 20px;
            }

            .btn {
                border-radius: 4px;
                font-size: 16px;
                border: 2px solid $softGreen;
                background-color: $softGreen;
                color: #fff;
                padding: 10px 25px;
                transition: 0.2s;
                // width: auto;

                // @media #{$tablet} {
                //     margin-bottom: 20px;
                // }
            }

            .btn:hover {
                background-color: rgb(0, 78, 8);
                border: 2px solid rgb(0, 78, 8);
            }
        }
    }


    .bor-block {
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        padding: 50px;
        text-align: center;
        margin: 25px auto;

        @media #{$mobile} {
            padding: 20px 15px 35px 15px;
        }

        h3 {
            font-size: 24px;
            font-weight: 600;
        }

        p {
            font-size: 18px;
            line-height: 30px;
            margin: 20px 0 40px;
        }

        .btn {
            color: white;
            font-size: 16px;
            border-radius: 4px;
            border-bottom: 2px solid rgb(1, 59, 7);
            background-color: $softGreen;
            padding: 12px 25px 10px;
            transition: 0.2s;
        }

        .btn:hover {
            background-color: rgb(0, 78, 8);
        }
    }