.banner {
    background: linear-gradient(rgba(0, 0, 0, 0.679),
            rgba(0, 0, 0, 0.679)), url(/src/images/baw.png);
    height: 550px;
    display: block;
    background-size: cover;
    background-position: center center;

    // &.short {
    //     height: 250px;
    // }

    .container {
        @media #{$tablet} {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        h1 {
            font-size: 48px;
            font-weight: 800;
            color: white;
            margin: 0;
            margin-bottom: 25px;

            @media #{$mobile} {
                text-align: center;
                font-size: 44px;
            }
        }

        p {
            font-size: 22px;
            color: white;
            margin: 0;
            margin-bottom: 40px;

            @media #{$tablet} {
                max-width: 50%;
                text-align: center;
            }

            @media #{$mobile} {
                max-width: 70%;
            }
        }

        .btn {
            color: white;
            font-size: 16px;
            border-radius: 4px;
            border-bottom: 2px solid rgb(1, 59, 7);
            background-color: $softGreen;
            padding: 12px 25px 10px;
            transition: 0.2s;
        }

        .btn:hover {
            background-color: rgb(0, 78, 8);
        }

    }
}

.banner-second {
    @extend .banner;
    height: 250px;
}