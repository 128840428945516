.parallax {
    background: linear-gradient(rgba(0, 0, 0, 0.679),
            rgba(0, 0, 0, 0.679)), url(/src/images/GazelN.png);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    height: 450px;

    @media #{$mobile} {
        height: 700px;
    }
}

.parallax-sec {
    background: linear-gradient(rgba(0, 0, 0, 0.679),
            rgba(0, 0, 0, 0.679)), url(/src/images/GazelN.png);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    height: 600px;

    @media #{$tablet} {
        height: 785px;
    }

    .head-block {
        .sec {
            margin-top: 40px;
            border: none;
            border-top: 2px solid #e3e3e3;
        }
    }


}

.container {

    .head-block {
        text-align: center;

        h2 {
            font-size: 36px;
            margin-top: 10px;
            color: white;

            hr {
                margin-top: 30px;
                border: none;
                border-top: 2px solid white;
                max-width: 45px;
                margin-bottom: 45px;
            }
        }

        p {
            font-size: 18px;
            font-weight: normal;
            color: white;
        }
    }

    .block-deck {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;

        @media #{$mobile} {
            flex-direction: column;
        }

        .block-item {
            text-align: center;

            span {
                display: block;
                color: white;
                font-size: 45px;
            }

            p {
                color: white;
                font-size: 20px;
            }
        }
    }

}