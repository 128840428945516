.map-block {
    .head-block {
        h2 {
            color: black;

            hr {
                border-top: 2px solid black;
                margin-bottom: 25px;
            }
        }

        p {
            color: black;
        }


    }
}