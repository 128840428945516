// PALETTE COLORS
$veryDarkDesaturatedBlue: black;
$softRed: hsl(14, 88%, 65%);
$veryDarkGrayishBlue: hsl(237, 12%, 33%);
$darkGrayishBlue: hsl(240, 6%, 50%);
$lightGrayishBlue: hsl(240, 5%, 91%);
$softViolet: hsl(273, 100%, 46%);
$softBlue: hsl(251, 82%, 32%);
$white: hsl(100, 100%, 100%);
$darkRed: rgb(113, 0, 0);
$softGreen: rgb(39, 100, 10);
// COLORS
$backgroundGradient: linear-gradient($softViolet, $softBlue);
$cardBackground: $white;
$headerColor: $veryDarkDesaturatedBlue;
$activeTitleColor: $veryDarkDesaturatedBlue;
$hoverTitleColor: $softRed;
$primaryTextColor: $veryDarkGrayishBlue;
$accordionBodyTextColor: $darkGrayishBlue;
$dividersColor: $lightGrayishBlue;

// BREAKPOINTS
$mobile-menu: "screen and (min-width: 767px)";
$mobile: "screen and (max-width: 767px)";
$tablet: "screen and (max-width: 991px)";
$laptop: "screen and (max-width: 1219px)";