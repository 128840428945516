.navbar {
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    background-color: $softGreen;
    padding: 10px;
    z-index: 2;
}

.logo-burger {
    display: none;
}

.nav-container {
    max-width: 1170px;
    margin: 0 auto;
}

.nav-links a {
    color: white;
    font-size: 18px;
    padding: 10px;
    margin: 0 2px;
}

.menu {
    display: flex;
    font-size: 18px;
    margin: 0 auto;
}

.menu li {
    padding: 5px 12px;
}

.menu li a:hover {
    transition: all 0.3s;
    background-color: rgb(0, 78, 8);
    border-radius: 5px;
}

#checkbox_toggle {
    display: none;
}

.hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

.hamburger-line {
    background-color: white;
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
}

.hamburger-line::before,
.hamburger-line::after {
    background-color: white;
    content: '';
    display: block;
    position: absolute;
    transition: all .3s;
    width: 100%;
    height: 100%;
}

.hamburger-line::before {
    top: 7px;
}

.hamburger-line::after {
    top: -7px;
}

@media (max-width: 768px) {
    .navbar {
        display: flex;
        justify-content: space-between;
    }

    .nav-container {
        max-width: 100%;
        margin: 0;
    }

    .logo-burger {
        display: block;

        h2 {
            a {
                color: white;
                font-size: 26px;

                &:hover {
                    cursor: pointer;
                }
            }

            padding: 0px 20px;
        }
    }

    .menu {
        overflow: hidden;
        background-color: rgb(0, 78, 8);
        position: absolute;
        max-height: 0;
        text-align: center;
        right: 0;
        left: 0;
        margin-top: 15px;
        z-index: 10;
        /*display: none;*/
    }

    .menu li a {
        display: block;
        padding: 13px;
    }

    .menu li {
        transition: all .2s;
    }

    .menu li:hover {
        background-color: $softGreen;
    }

    .menu li a:hover {
        border: none;
        background-color: $softGreen;
    }

    #checkbox_toggle:checked~.menu {
        display: block;
        max-height: 500px;
        transition: all .5s;
    }

    .hamburger {
        display: block;
        padding: 15px 0;
    }
}