.popup_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.679);
    opacity: 0;
    pointer-events: none;
    transition: .5s all;
    z-index: 3;

}

.popup_bg.active {
    opacity: 1;
    pointer-events: all;
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    background: #fff;
    // border: 2px solid rgb(0, 0, 0);
    border-radius: 5px;
    width: 280px;
    padding: 25px;
    transition: .5s ease;

    .popup_header {
        text-align: center;

        p {
            font-size: 24px;
            margin: 25px 0;
            color: rgb(0, 0, 0);
        }

        .close_popup {
            position: absolute;
            top: 10px;
            right: 10px;

            i {
                font-size: 22px;
                transition: .5s ease;
                cursor: pointer;
                color: $softGreen;

                &:hover {
                    color: black;
                }
            }
        }
    }

    label {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column-reverse;

        .label_text {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: #666;
        }

        input {
            height: 35px;
            font-size: 18px;
            border: none;
            outline: none;
            border-bottom: 1px solid #666;

            &:focus {
                border-bottom: 1px solid $softGreen;
            }

            &:focus+.label_text {
                color: $softGreen;
            }
        }

        textarea {
            font-size: 18px;
            padding-top: 5px;
            resize: none;
            width: 100%;
            height: 120px;
            border: none;
            outline: none;
            border-bottom: 1px solid #666;

            &:focus {
                border-bottom: 1px solid $softGreen;
            }

            &:focus+.label_text {
                color: $softGreen;
            }
        }
    }

    button {
        width: 100%;
        height: 45px;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        border: 2px solid $softGreen;
        border-radius: 4px;
        background-color: $softGreen;
        transition: .3s ease;

        &:hover {
            background-color: white;
            color: $softGreen;
        }
    }
}

.popup.active {
    transform: translate(-50%, -50%) scale(1);
}