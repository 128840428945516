.bggrey {
    background-color: rgb(244, 244, 244);
}

.hand_p {
    h2 {
        font-size: 36px;
        margin-top: 10px;
        text-align: center;

        hr {
            margin-top: 30px;
            border: none;
            border-top: 2px solid #000;
            max-width: 45px;
        }
    }
}

.hand {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    justify-content: space-between;

    @media #{$mobile} {
        justify-content: none;
        flex-direction: column;
        align-items: center;
    }

    .blok_icon {
        text-align: center;
        max-width: 330px;
        padding: 15px;

        @media #{$tablet} {
            max-width: 40%;
        }

        @media #{$mobile} {
            max-width: 70%;
        }

        i {
            font-size: 30px;
            border: 1px solid $softGreen;
            border-radius: 50%;
            padding: 30px;
            color: $softGreen;
            transition: 0.5s;
        }

        i:hover {
            background-color: $softGreen;
            border: 1px solid $softGreen;
            color: white;
        }

        h3 {
            font-size: 24px;
            font-weight: 400;
            margin: 18px 0;

            @media #{$mobile} {
                font-size: 18px;
                font-weight: bold;
            }
        }

        p {
            font-size: 18px;
            color: #666;
            line-height: 30px;

            @media #{$mobile} {
                font-size: 15px;
                font-weight: bold;
            }
        }
    }
}