.hover-head {
    h2 {
        font-size: 36px;
        margin-top: 10px;
        text-align: center;

        hr {
            margin-top: 30px;
            border: none;
            border-top: 2px solid #000;
            max-width: 45px;
        }
    }
}