.container {
  max-width: 1170px;
  width: 100%;
  padding: 40px 15px;
  margin: 0 auto;

  @media #{$tablet} {
    max-width: 80%;
  }

  @media #{$laptop} {
    max-width: 80%;
  }

  @media #{$mobile} {
    max-width: 100%;
  }
}

// .container-blog {
//   @extend .container;
//   padding: 0 15px
// }

.container-max {
  @extend .container;

  @media #{$tablet} {
    max-width: 100%;
  }
}

.container-top {
  padding: 60px 0px;
}

.fixed-container {
  max-width: 1140px;
  margin: 0 auto;

  @media #{$tablet} {
    padding: 0 15px;
  }
}

.container-fix {
  @media #{$mobile} {
    padding-bottom: 70px;
  }
}

.container_bottom {
  @extend .container;
  padding: 20px 15px;
}