.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$tablet} {
        flex-direction: column;
    }

    .logo-deck {
        max-width: 35%;

        @media #{$laptop} {
            max-width: 43%;
        }

        @media #{$tablet} {
            max-width: 100%;
        }

        .logo {
            display: flex;

            @media #{$mobile} {
                text-align: center;
            }

            img {
                max-width: 20%;
                width: 100px;
            }

            a {
                font-size: 36px;
                font-weight: bold;

                @media #{$mobile} {
                    font-size: 30px;
                }
            }


        }

        h2 {
            font-size: 22px;
            font-weight: normal;

            @media #{$tablet} {
                text-align: center;
                display: none;
            }
        }
    }

    .he-deck {
        display: flex;
        justify-content: space-between;
        width: 60%;

        @media #{$tablet} {
            display: none;
        }

        @media #{$laptop} {
            justify-content: space-evenly;
            width: 50%;
        }

        .he-block-tablet {
            @media #{$laptop} {
                display: none;
            }
        }

        .he-block {
            display: flex;

            .icon {
                display: flex;
                align-items: center;
                padding-right: 15px;

                i {
                    font-size: 25px;
                    color: $softGreen;
                }
            }

            .he-text,a {
                font-size: 16px;
                font-weight: bold;

                span,a {
                    display: block;
                    font-size: 16px;
                    font-weight: normal;
                    color: #666;
                    padding-top: 3px;
                }
            }
        }
    }
}