.bg-footer-top {
    background-color: rgb(50, 50, 50);


    a {
        color: rgba(245, 245, 245, 0.63529);
        font-size: inherit;
    }

    @media #{$mobile} {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        height: 70px;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media #{$laptop} {
            max-width: 90%;
        }

        @media #{$tablet} {
            max-width: 100%;
        }

        .footer-items {
            max-width: 65%;
            width: 100%;
            display: flex;
            justify-content: space-between;

            @media #{$mobile} {
                flex-direction: column;
            }

            @media #{$tablet} {
                //max-width: 50%;
            }

            @media #{$laptop} {
                max-width: 70%;
            }

            .foot-item {

                .footer-text {
                    font-size: 14px;
                    display: block;
                    color: #f5f5f5a2;
                    line-height: 180%;

                    @media #{$mobile} {
                        display: none;
                    }
                }

                .footer-text.none {
                    @media #{$mobile} {
                        display: block;
                        font-size: 18px;
                    }
                }
            }
        }

        .foot-icons {
            width: 15%;
            display: flex;
            justify-content: space-evenly;

            @media #{$mobile} {
                width: 50%;
            }
        }
    }
}

.bg-footer-bottom {
    background-color: rgb(35, 35, 35);

    .container {
        text-align: center;

        h3 {
            font-size: 18px;
            color: #f5f5f5a2;
            font-weight: 400;
        }

        p {
            font-size: 16px;
            color: #f5f5f5a2;
            margin-bottom: 0;
        }
    }
}