.second-nav {
    display: flex;
    margin-bottom: 30px;

    span {
        font-size: 20px;
        margin: 0 5px;
    }

    a {
        display: block;
        font-size: 20px;
        color: $softGreen;

        &:hover {
            color: black;
        }
    }
}

.fb {
    background-color: $softGreen;
}

.contact {
    font-size: 18px;
}

.hand_p {
    h2 {
        text-align: left;

        hr {
            margin: 20px 0 25px;
        }
    }

}

.contacts {
    h3 {
        font-size: 20px;
    }

    ul {
        li {
            list-style-type: circle;
        }
    }

    p {
        font-size: 18px;
        line-height: 32px;
    }

    a {
        display: block;
        position: relative;
    }

    .viber::before {

        content: "";
        position: absolute;
        // left: -40px;
        top: 5px;
        // display: block;
        width: 26px;
        height: 26px;
        background: url(/src/images/viber.png);
        background-size: cover;
        cursor: pointer;

    }

    .whatsapp::before {
        content: "";
        position: absolute;
        // left: -40px;
        top: 5px;
        // display: block;
        width: 27px;
        height: 26px;
        background: url(/src/images/whatsapp.svg);
        background-size: cover;
        cursor: pointer;
    }

    .telegram::before {
        content: "";
        position: absolute;
        // left: -40px;
        top: 5px;
        // display: block;
        width: 26px;
        height: 26px;
        background: url(/src/images/telegram.svg);
        background-size: cover;
        cursor: pointer;
    }

    .icon_text {
        position: relative;
        left: 30px;
        top: 2px;
    }
}

table {
    border: 2px;

    tr {
        padding: 30px;
    }
}

.bold {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
}