@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

* {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: black;
  //line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $headerColor;
}