.feedback {
    h2 {
        font-size: 36px;
        margin: 30px auto 0;
        text-align: center;

        hr {
            margin-top: 30px;
            border: none;
            border-top: 2px solid rgb(0, 0, 0);
            max-width: 45px;
            margin-bottom: 45px;
        }

    }
}

.feed_block {
    .block_item {
        display: flex;
        border: 1px solid #e3e3e3;
        background-color: #fff;
        padding: 20px;
        margin-bottom: 30px;
        border-radius: 5px;
        box-shadow: 0 1px 1px rgb(201, 201, 201);

        .block_image {
            display: flex;
            align-items: center;
            margin-right: 15px;

            // @media #{$tablet} {
            //     display: none;
            // }

            img {
                border-radius: 50%;
                height: 64px;
                width: 64px;
                object-fit: cover;
            }
        }

        .block_info {
            max-width: 90%;

            @media #{$tablet} {
                max-width: 100%;
            }

            p {
                font-size: 18px;
                line-height: 30px;
            }

            span {
                display: block;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}