.nav {
    display: flex;
    justify-content: center;
    list-style-type: none;
    align-items: center;
    height: 60px;
    margin: 0 auto;

    li {
        padding: 10px 25px;
        position: relative;
        transition: 0.2s;
        margin: 0 2px;

        a {
            font-size: 16px;
            font-weight: 700;
            color: white;
        }
    }

    li:hover {

        background-color: rgb(0, 78, 8);
        border-radius: 4px;
        padding: 10px 25px;
    }
}