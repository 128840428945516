  .blog {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;
      position: relative;

      @media #{$tablet} {
          flex-direction: column;
      }

      .blog_menu {
          max-width: 260px;
          width: 100%;

          @media #{$tablet} {
              max-width: 780px;
              margin: 0 auto;
          }


          .menu_items {
              ul {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  margin: 0;

                  @media #{$tablet} {
                      flex-direction: row;
                      overflow: scroll;
                  }

                  li {
                      border: 1px solid white;
                      height: 45px;
                      background: $softGreen;
                      padding: 4px 8px;
                      display: flex;
                      align-items: center;
                      border-radius: 3px;
                      width: 100%;
                      cursor: pointer;

                      @media #{$tablet} {
                          height: 50px;
                      }


                      &.active {
                          background: rgb(0, 63, 7);
                      }

                      //   justify-content: center;
                      &:hover {
                          background: rgb(0, 78, 8);
                      }

                      a {
                          color: white;
                          font-size: 14px;
                          width: 100%;

                          @media #{$tablet} {
                              width: 110px;
                              text-align: center;
                              font-size: 12px;
                          }
                      }
                  }
              }
          }
      }

      .blog_content {
          max-width: 780px;
          width: 100%;

          @media #{$tablet} {
              margin: 0 auto;
          }

          img {
              max-width: 100% !important;
              width: 100% !important;
          }

          .glider-img {
              //   max-width: 450px;
              width: 100% !important;
              height: 500px !important;


              @media #{$tablet} {
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
              }
          }

          h1 {
              font-size: 24px;
              font-weight: bold;
              padding: 15px 0;
          }

          .blog_text {
              p {
                  font-size: 18px;

                  b {
                      font-size: 20px;
                  }
              }

              ul {
                  li {
                      font-size: 18px;
                  }
              }

          }
      }
  }